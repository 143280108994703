import { useContext, useEffect, useState } from "react";
import LeftPanelImage from "../microcomponents/LeftPanelImage";
import { ContactLeftPanelItems } from "../../Data/ContactLeftPanelItems";
import ContactLeftPanelMenuItem from "../microcomponents/ContactLeftPanelMenuItem";
import LeftPanelUserDetails from "../microcomponents/LeftPanelUserDetails";
import DarkModeSelection from "../microcomponents/DarkModeSelection";
import SignoutLeftPanel from "../microcomponents/SignoutLeftPanel";
import { Skeleton } from "primereact/skeleton";
import { updatePanelItems } from "app/utils/utils";
import { ContactProfileDetailsContext } from "./ContactLayout";
import LeftPanelBrandingLogo from "../microcomponents/LeftPanelBrandingLogo";
import { getObjectFromSessionStore, setObjectInSessionStore } from "app/utils/sessionStore";
import { makeRequest } from "app/utils/makeRequest";
import { Tooltip } from "primereact/tooltip";

const ContactLeftPanel = () => {
  const { contactFirstName, contactLastName, isOpen, setIsOpen } = useContext(
    ContactProfileDetailsContext,
  );
  const propStats = getObjectFromSessionStore("proposalStats");
  const [proposalStats, setProposalStats] = useState<any>(propStats ?? {});

  const updateProposalStats = () => {
    makeRequest("proposalStats")
      .get()
      .then((res) => {
        setProposalStats(res.data.data ?? {});
        setObjectInSessionStore("proposalStats", res.data.data ?? {});
      });
  };

  useEffect(() => {
    updateProposalStats();
  }, []);

  const ContactLeftPanelItemsUpdated = updatePanelItems(ContactLeftPanelItems, proposalStats);

  return (
    <div className={`px-1 mb-4 relative z-[100] ${isOpen ? "w-[16.5rem]" : "w-[8.75rem]"}`}>
      <aside
        className="py-5 h-[100vh] fixed z-100 ml-2"
        style={{ width: isOpen ? "15.625rem" : "8rem" }}
      >
        <nav
          className={`scrollbar-hide h-full flex flex-col justify-content-between overflow-y-auto border-r border-primary-bg-border border-[1px] shadow-lg rounded-2xl pt-3 pb-4 bg-light-primary dark:bg-blue-1100 ${
            isOpen ? "px-2" : "px-3"
          }`}
          style={{
            scrollbarWidth: "thin",
          }}
        >
          <div>
            <div className="absolute group right-[-20px] top-2 pb-2 flex justify-between items-center">
              <button
                type="button"
                onClick={() => {
                  setIsOpen((prev: any) => !prev);
                }}
                className="flex items-center justify-center rounded-lg  "
              >
                {isOpen ? (
                  <i
                    className="left-angles pi pi-angle-double-left p-2 rounded-lg bg-light-primary border-[1px] border-gray-300 dark:!border-colorBoder dark:bg-blue-1000 hover:bg-gray-100 dark:hover:!bg-blue-800"
                    style={{ fontSize: "1rem" }}
                    data-pr-tooltip="Collapse"
                  >
                    <Tooltip target=".left-angles" className="tooltip-wrapper" />
                  </i>
                ) : (
                  <i
                    className="right-angles pi pi-angle-double-right  p-2 rounded-lg  bg-light-primary border-[1px] border-gray-300    dark:border-[1px] dark:!border-colorBoder dark:bg-blue-1000 hover:bg-gray-100 dark:hover:!bg-blue-800"
                    style={{ fontSize: "1rem" }}
                    data-pr-tooltip="Expand"
                  >
                    <Tooltip target=".right-angles" className="tooltip-wrapper" />
                  </i>
                )}
              </button>
            </div>
            <LeftPanelBrandingLogo />
            <LeftPanelImage />
            <h4
              className={`text-xl font-semibold !text-grayText text-center my-3 transition-opacity  ${
                isOpen ? "opacity-100" : "opacity-0 hidden"
              }`}
            >
              {contactFirstName || contactLastName ? (
                `${contactFirstName} ${contactLastName}`
              ) : (
                <Skeleton width="100%" height="3rem" borderRadius="16px" className="mb-2" />
              )}
            </h4>
            <div className="mt-4 mb-6 flex justify-center">
              <div
                className={`${isOpen ? "w-full" : "!w-[3.5rem]"} h-[1px] bg-gray-500 rounded-md `}
              />
            </div>
            <div className="flex justify-center items-center">
              <ul className={`leftPanelUl flex-1 text-center mb-3 ${!isOpen ? "w-[48px]" : ""}`}>
                {ContactLeftPanelItemsUpdated.map(
                  (item: any) =>
                    !item.hide && (
                      <ContactLeftPanelMenuItem
                        key={item.id}
                        directTo={item.key}
                        name={item.name}
                        icon={item.icon}
                      />
                    ),
                )}
              </ul>
            </div>
          </div>
          <div className="flex-grow">
            <div className="w-full flex  justify-center items-center mt-4">
              <LeftPanelUserDetails />
            </div>
            <DarkModeSelection />
            <SignoutLeftPanel />
          </div>
        </nav>
      </aside>
    </div>
  );
};

export default ContactLeftPanel;
