import { useContext } from "react";
import { ThemeContext } from "../macrocomponents/RootLayout";
import BeeLight from "../../assets/BEE _ICON _WHITE.png";
import BeeDark from "../../assets/BEE.png";

const Footer = () => {
  const { isDarkMode } = useContext(ThemeContext);

  // If you change image height and text size then change the "footerHeight" in utils also,
  // it should be in rem and calculated when "data-font" is 'regular' or html font size is 16px.
  return (
    <div className="mt-auto flex flex-col justify-center items-center py-2">
      <img src={isDarkMode ? BeeLight : BeeDark} alt="IAPP Logo" className="h-[2rem] w-[2rem]" />
      <div className="text-center pt-2 font-semibold text-xs text-gray-500">
        <p>Insurance Advanced Planning Platform LLC</p>
        <p>{new Date().getFullYear()} All Rights Reserved</p>
      </div>
    </div>
  );
};
export default Footer;
