import { getSessionStore } from "app/utils/sessionStore";
import { FilePreviewInterface, THEME } from "app/utils/types";
import { capitalizeFirstLetter } from "app/utils/utils";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";
import { Sidebar } from "primereact/sidebar";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImFileZip } from "react-icons/im";

const FilePreview: FC<FilePreviewInterface> = ({
  url,
  isHalfWidth = true,
  onCancel,
  loading,
  docCategory,
  formattedDateSubmitted,
  setButtons = <></>,
  content = null,
}) => {
  const { t } = useTranslation();
  const globalTheme = getSessionStore("globalTheme");
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [docCategory]);

  const containsNumbers = (str: any) => /\d/.test(str);

  const downloadFile = () => {
    fetch(url, { method: "get" })
      .then((response) => response.blob())
      .then((response) => {
        const href = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", docCategory || "doc");
        document.body.appendChild(link);
        link.click();
      });
  };

  const renderContent = () => {
    if (content) {
      return content;
    }

    if (!url) {
      return (
        <p className="w-full h-30rem flex-1 border rounded-md border-gray-500">
          {t("pdfNotGeneratedText")}
        </p>
      );
    }

    if (url.includes(".zip")) {
      return <ImFileZip style={{ height: "50px", width: "50px" }} />;
    }

    return (
      <iframe
        title="PDF Viewer"
        src={`${url}#toolbar=0&navpanes=0`}
        className="w-full h-full flex-1 border rounded-md border-gray-500"
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    );
  };

  return (
    <Sidebar
      visible={!!url || isLoading}
      position="right"
      className={`z-50 ${isHalfWidth ? "!w-1/2 overflow-auto" : "!w-full overflow-auto"} ${
        globalTheme === THEME.DARK ? " " : "bg-white"
      }`}
      onHide={onCancel}
    >
      <div
        className={`flex ${
          globalTheme === THEME.DARK ? " " : "bg-white"
        } flex-col justify-between h-full overflow-auto`}
      >
        <div>
          <div className="p-2 border flex items-end">
            <div className="flex-grow">
              <div className="text-xl">
                {docCategory && containsNumbers(docCategory)
                  ? docCategory
                  : docCategory
                      ?.split(/(?=[A-Z])/)
                      ?.map((el) => capitalizeFirstLetter(el))
                      ?.join(" ")}
              </div>
            </div>

            <div className="text-right text-md ml-auto">
              <Button
                className="rounded-full !bg-light-gray hover:!bg-gray-700 border-0 !h-7 !w-7 mr-4"
                icon="pi pi-download"
                onClick={() => downloadFile()}
              />
              {formattedDateSubmitted}
            </div>
          </div>

          <Divider type="solid" className="custom-divider" />

          <div
            className={`w-full h-[calc(100vh-${setButtons ? "250px" : "150px"})]`}
            style={{
              height: setButtons ? "calc(100vh - 250px)" : "calc(100vh - 150px)",
            }}
          >
            {isLoading ? (
              <ProgressSpinner
                className={
                  isHalfWidth
                    ? "!w-1/2 items-center flex justify-center"
                    : "!w-full items-center flex justify-center"
                }
              />
            ) : (
              renderContent()
            )}
          </div>
        </div>
        {setButtons}
      </div>
    </Sidebar>
  );
};

export default FilePreview;
