import React, { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import useDarkTheme from "app/hooks/useDarkTheme";
import { getSessionStore, setSessionStore } from "app/utils/sessionStore";
import { TRIGGER_SAVE_THEME_SETTINGS } from "app/redux/features/agent-branding";
import { useDispatch } from "react-redux";
import { ContactProfileDetailsContext } from "../macrocomponents/ContactLayout";
import PrimeReact from "primereact/api";
import { ThemeContext } from "../macrocomponents/RootLayout";

function DarkModeSelection() {
  const { branding = "" } = getSessionStore("lookUpResponse") ?? {};
  const { theme: appTheme, primaryColor = "" } = branding ?? {};
  const { isOpen } = useContext(ContactProfileDetailsContext);
  const { isDarkMode, setIsDarkMode } = useContext(ThemeContext);
  const [, setTheme]: [string, Dispatch<SetStateAction<string>>] = useDarkTheme();
  const dispatch = useDispatch();
  useEffect(() => {
    setTheme(isDarkMode ? "dark" : "light");
    setSessionStore("globalTheme", isDarkMode ? "dark" : "light");
    const themeSelectionSlider = document.querySelector(".p-inputswitch-slider") as HTMLElement;
    const sliderColor = isDarkMode ? primaryColor || "#002446" : "#ced4da";
    themeSelectionSlider.style.backgroundColor = sliderColor;
  }, [isDarkMode]);

  const onThemeSelect = (value?: boolean) => {
    const newTheme = value ? "dark" : "light";
    const oldTheme = newTheme === "light" ? "dark" : "light";
    setSessionStore("globalTheme", value ? "dark" : "light");
    PrimeReact?.changeTheme?.(`lara-${oldTheme}-blue`, `lara-${newTheme}-blue`, "app-theme", () => {
      const allThemeLinks = document.querySelectorAll("link#app-theme");
      if (allThemeLinks.length > 1) {
        for (let i = 1; i < allThemeLinks.length; i++) {
          allThemeLinks[i].remove();
        }
      }
    });
    dispatch({
      type: TRIGGER_SAVE_THEME_SETTINGS,
      payload: {
        address: "saveThemeSettings",
        body: { theme: value ? "dark" : "light" },
      },
    });
  };

  useEffect(() => {
    appTheme === "dark" && setIsDarkMode(true);
  }, [appTheme]);

  return (
    <div className="full p-2 mt-2 relative group">
      <div
        className={`flex  gap-2 items-center justify-center ${isOpen ? "flex-row" : "flex-col"} `}
      >
        <i className={`pi pi-sun ${isOpen ? "mr-2 mt-1" : "mb-2"}`} style={{ fontSize: "1rem" }} />
        <InputSwitch
          tooltip="Mode"
          tooltipOptions={{
            className: "tooltip-wrapper",
          }}
          checked={isDarkMode}
          onChange={(e: InputSwitchChangeEvent) => {
            setIsDarkMode(e.value ?? false);
            onThemeSelect(e.value as boolean);
          }}
        />
        <i className={`pi pi-moon ${isOpen ? "ml-2" : "mt-2"}`} style={{ fontSize: "1rem" }} />
      </div>
    </div>
  );
}

export default DarkModeSelection;
