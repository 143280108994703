import React, { createContext, useEffect, useMemo, useState } from "react";
import ContactDashBoardHeader from "../microcomponents/ContactDashBoardHeader";
import ContactLeftPanel from "./ContactLeftPanel";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getContactInformation,
  TRIGGER_USER_PROFILE_REQUEST,
} from "app/redux/features/contactById";
import { getObjectFromLocalStore } from "app/utils/localStore";
import { formatPhoneNumber } from "app/utils/utils";
import { setObjectInSessionStore } from "app/utils/sessionStore";

export const ContactProfileDetailsContext = createContext<{
  contactId: string;
  contactProfilePic: string;
  contactFirstName: string;
  contactLastName: string;
  agentProfilePic: string;
  agentName: string;
  agentEmail: string;
  agentPhone: null | string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  contactId: "",
  contactProfilePic: "",
  contactFirstName: "",
  contactLastName: "",
  agentProfilePic: "",
  agentName: "",
  agentEmail: "",
  agentPhone: "",
  isOpen: true,
  setIsOpen: () => {},
});

function ContactLayout() {
  const {
    agentContactSuccess,
    agentContactProfilePictureUploadSuccess,
    agentContactBasicInfoEditSuccess,
  } = useSelector(getContactInformation);

  const [isOpen, setIsOpen] = useState(true);

  const userAccess = getObjectFromLocalStore("useraccess");
  const dispatch = useDispatch();

  const contactId = agentContactSuccess?.data?.id ?? "";
  const contactProfilePic = agentContactSuccess?.data?.profilePicture ?? "";
  const contactFirstName = agentContactSuccess?.data?.firstName ?? "";
  const contactLastName = agentContactSuccess?.data?.lastName ?? "";
  const agentProfilePic = agentContactSuccess?.data?.agent?.profilePicture ?? "";
  const agentName = agentContactSuccess?.data?.agent?.name ?? "";
  const agentEmail = agentContactSuccess?.data?.agent?.email ?? "";
  const agentPhone = formatPhoneNumber(agentContactSuccess?.data?.agent?.contact?.phoneNumber);

  setObjectInSessionStore("contactId", { id: agentContactSuccess?.data?.id ?? "" });

  const contextValue = useMemo(
    () => ({
      contactId,
      contactProfilePic,
      contactFirstName,
      contactLastName,
      agentProfilePic,
      agentName,
      agentEmail,
      agentPhone,
      isOpen,
      setIsOpen,
    }),
    [
      contactId,
      agentContactSuccess,
      contactFirstName,
      contactLastName,
      agentProfilePic,
      agentName,
      agentEmail,
      agentPhone,
      isOpen,
      setIsOpen,
    ],
  );

  useEffect(() => {
    if (userAccess?.userType === "contact") {
      dispatch({
        type: TRIGGER_USER_PROFILE_REQUEST,
        payload: {
          address: "contact",
        },
      });
    }
  }, [agentContactProfilePictureUploadSuccess?.data, agentContactBasicInfoEditSuccess?.data]);

  return (
    <ContactProfileDetailsContext.Provider value={contextValue}>
      <div className="relative h-screen">
        <ContactDashBoardHeader />
        <div className="h-full flex bg-[var(--surface-ground)]">
          <ContactLeftPanel />
          <div className="flex-1 overflow-auto h-[calc(100vh-190px)] mt-auto scrollbar-hide">
            <Outlet />
          </div>
        </div>
      </div>
    </ContactProfileDetailsContext.Provider>
  );
}

export default ContactLayout;
