import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const PROPOSED_LIFE_POLICY_LEDGER = "PROPOSED_LIFE_POLICY_LEDGER";
export const POST_PROPOSED_LIFE_POLICY_LEDGER = "POST_PROPOSED_LIFE_POLICY_LEDGER";
export const PUT_PROPOSED_LIFE_POLICY_LEDGER = "PUT_PROPOSED_LIFE_POLICY_LEDGER";
export const DELETE_PROPOSED_LIFE_POLICY_LEDGER = "DELETE_PROPOSED_LIFE_POLICY_LEDGER";

interface ResponseType {
  data?: any;
  error?: any;
  loading?: boolean;
}

interface ProposedPolicyLedger {
  fetchProposedPolicyLedgerStart: ResponseType;
  fetchProposedPolicyLedgerSuccess: ResponseType;
  fetchProposedPolicyLedgerFailure: ResponseType;
  postProposedPolicyLedgerStart: ResponseType;
  postProposedPolicyLedgerSuccess: ResponseType;
  postProposedPolicyLedgerFailure: ResponseType;
  editProposedPolicyLedgerStart: ResponseType;
  editProposedPolicyLedgerSuccess: ResponseType;
  editProposedPolicyLedgerFailure: ResponseType;
  deleteProposedPolicyLedgerStart: ResponseType;
  deleteProposedPolicyLedgerSuccess: ResponseType;
  deleteProposedPolicyLedgerFailure: ResponseType;
}

const initialState: ProposedPolicyLedger = {
  fetchProposedPolicyLedgerStart: { data: {}, error: {}, loading: false },
  fetchProposedPolicyLedgerSuccess: { data: {}, loading: false },
  fetchProposedPolicyLedgerFailure: { error: {}, loading: false },
  postProposedPolicyLedgerStart: { data: {}, error: {}, loading: false },
  postProposedPolicyLedgerSuccess: { data: {}, loading: false },
  postProposedPolicyLedgerFailure: { error: {}, loading: false },
  editProposedPolicyLedgerStart: { data: {}, error: {}, loading: false },
  editProposedPolicyLedgerSuccess: { data: {}, loading: false },
  editProposedPolicyLedgerFailure: { error: {}, loading: false },
  deleteProposedPolicyLedgerStart: { data: {}, error: {}, loading: false },
  deleteProposedPolicyLedgerSuccess: { data: {}, loading: false },
  deleteProposedPolicyLedgerFailure: { error: {}, loading: false },
};

export const proposedLifePolicyLedgerSlice = createSlice({
  name: "proposedLifePolicyLedger",
  initialState,
  reducers: {
    fetchProposedPolicyLedgerStart: (state) => {
      state.fetchProposedPolicyLedgerSuccess = { data: [], error: {}, loading: true };
    },
    fetchProposedPolicyLedgerSuccess: (state, action: PayloadAction<any>) => {
      state.fetchProposedPolicyLedgerSuccess = { data: action.payload, loading: false };
    },
    fetchProposedPolicyLedgerFailure: (state, action: PayloadAction<any>) => {
      state.fetchProposedPolicyLedgerFailure = { error: action.payload, loading: false };
    },
    postProposedPolicyLedgerStart: (state) => {
      state.postProposedPolicyLedgerStart = { data: {}, error: {}, loading: true };
    },
    postProposedPolicyLedgerSuccess: (state, action: PayloadAction<any>) => {
      state.postProposedPolicyLedgerSuccess = { data: action.payload, loading: false };
    },
    postProposedPolicyLedgerFailure: (state, action: PayloadAction<any>) => {
      state.postProposedPolicyLedgerFailure = { error: action.payload, loading: false };
    },
    editProposedPolicyLedgerStart: (state) => {
      state.editProposedPolicyLedgerStart = { data: {}, error: {}, loading: true };
    },
    editProposedPolicyLedgerSuccess: (state, action: PayloadAction<any>) => {
      state.editProposedPolicyLedgerSuccess = { data: action.payload, loading: false };
    },
    editProposedPolicyLedgerFailure: (state, action: PayloadAction<any>) => {
      state.editProposedPolicyLedgerFailure = { error: action.payload, loading: false };
    },
    deleteProposedPolicyLedgerStart: (state) => {
      state.deleteProposedPolicyLedgerStart = { data: {}, error: {}, loading: true };
    },
    deleteProposedPolicyLedgerSuccess: (state, action: PayloadAction<any>) => {
      state.deleteProposedPolicyLedgerSuccess = { data: action.payload, loading: false };
    },
    deleteProposedPolicyLedgerFailure: (state, action: PayloadAction<any>) => {
      state.deleteProposedPolicyLedgerFailure = { error: action.payload, loading: false };
    },
    resetLifePolicyLedgerState: () => initialState,
    resetLifePolicyErrorState: (state) => {
      state.postProposedPolicyLedgerStart = { data: {}, error: {}, loading: false };
      state.postProposedPolicyLedgerSuccess = { data: {}, loading: false };
      state.postProposedPolicyLedgerFailure = { error: {}, loading: false };
    },
    resetEditLifePolicyErrorState: (state) => {
      state.editProposedPolicyLedgerStart = { data: {}, error: {}, loading: false };
      state.editProposedPolicyLedgerSuccess = { data: {}, loading: false };
      state.editProposedPolicyLedgerFailure = { error: {}, loading: false };
    },
  },
});

export const {
  fetchProposedPolicyLedgerStart,
  fetchProposedPolicyLedgerSuccess,
  fetchProposedPolicyLedgerFailure,
  postProposedPolicyLedgerStart,
  postProposedPolicyLedgerSuccess,
  postProposedPolicyLedgerFailure,
  editProposedPolicyLedgerStart,
  editProposedPolicyLedgerSuccess,
  editProposedPolicyLedgerFailure,
  deleteProposedPolicyLedgerStart,
  deleteProposedPolicyLedgerSuccess,
  deleteProposedPolicyLedgerFailure,
  resetLifePolicyLedgerState,
  resetLifePolicyErrorState,
  resetEditLifePolicyErrorState,
} = proposedLifePolicyLedgerSlice.actions;

export const proposedPolicyLifeLedgerReducer = (state: RootState) => state.proposedLifePolicyLedger;

export default proposedLifePolicyLedgerSlice.reducer;
