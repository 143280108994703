import {
  TRIGGER_CREATE_BUYSELL_PROPOSAL_CONTACT_FETCH_REQUEST,
  TRIGGER_CREATE_BUYSELL_MASTER_DATA_PROVISION_FETCH_REQUEST,
  TRIGGER_CREATE_BUYSELL_PROVISION_FETCH_REQUEST,
  TRIGGER_CREATE_BUYSELL_PROVISION_POST_REQUEST,
  createBuySellProposalContactInfoFetchSuccess,
  createBuySellProposalContactInfoFetchError,
  createBuySellProposalMasterDataProvisionFetchSuccess,
  createBuySellProposalMasterDataProvisionFetchError,
  createBuySellProposalProvisionFetchSuccess,
  createBuySellProposalProvisionFetchError,
  createBuySellProposalProvisionPostSuccess,
  createBuySellProposalProvisionPostError,
  getProposalStart,
  getProposalSuccess,
  getProposalError,
  putBSProposalStart,
  putBSProposalSuccess,
  putBSProposalError,
  TRIGGER_CREATE_BUYSELL_GET_PROPOSAL,
  PUT_POLICY_DETAILS,
} from "app/redux";
import { createBuySellProposalContactInfoFetchLoading } from "app/redux/features/createbuySellProposal";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* createBuySellProposalContactInfoRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(createBuySellProposalContactInfoFetchLoading());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(createBuySellProposalContactInfoFetchSuccess(response.data));
  } catch (err: any) {
    yield put(createBuySellProposalContactInfoFetchError(err));
  }
}

function* createBuySellProposalMasterDataProvisionRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key },
    } = actions;
    const response = yield call(() => makeRequest(path).get(key));
    yield put(createBuySellProposalMasterDataProvisionFetchSuccess(response.data));
  } catch (err: any) {
    yield put(createBuySellProposalMasterDataProvisionFetchError(err));
  }
}

function* createBuySellProposalProvisionRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(createBuySellProposalProvisionFetchSuccess(response.data));
  } catch (err: any) {
    yield put(createBuySellProposalProvisionFetchError(err));
  }
}

function* createBuySellProposalProvisionPostRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, body },
    } = actions;
    const response = yield call(() => makeRequest(path).post(body, key));
    yield put(createBuySellProposalProvisionPostSuccess(response.data));
  } catch (err: any) {
    yield put(createBuySellProposalProvisionPostError(err));
  }
}
function* getProposal(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(getProposalStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(getProposalSuccess(response.data));
  } catch (error: any) {
    yield put(getProposalError(error));
  }
}

function* putProposalBS({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(putBSProposalStart());
    const response = yield call(() =>
      makeRequest(address).put(key, body, requestParams, queryParams),
    );

    yield put(putBSProposalSuccess(response.data));
  } catch (error: any) {
    yield put(putBSProposalError(error));
  }
}

function* createBuySellProposal() {
  yield takeLatest(
    TRIGGER_CREATE_BUYSELL_PROPOSAL_CONTACT_FETCH_REQUEST,
    createBuySellProposalContactInfoRequest,
  );
  yield takeLatest(
    TRIGGER_CREATE_BUYSELL_MASTER_DATA_PROVISION_FETCH_REQUEST,
    createBuySellProposalMasterDataProvisionRequest,
  );
  yield takeLatest(
    TRIGGER_CREATE_BUYSELL_PROVISION_FETCH_REQUEST,
    createBuySellProposalProvisionRequest,
  );
  yield takeLatest(
    TRIGGER_CREATE_BUYSELL_PROVISION_POST_REQUEST,
    createBuySellProposalProvisionPostRequest,
  );
  yield takeLatest(TRIGGER_CREATE_BUYSELL_GET_PROPOSAL, getProposal);
  yield takeLatest(PUT_POLICY_DETAILS, putProposalBS);
}

export default function* createBuySellProposalSaga() {
  yield all([createBuySellProposal()]);
}
