import { getSessionStore } from "app/utils/sessionStore";
import IappLightModeLogo from "../../assets/IAPPLogoColor.svg";
import IappDarkModeLogo from "../../assets/IAPPLogoDarkMode.svg";
import BEEICON from "../../assets/BEE.png";
import BEEICONWHITE from "../../assets/BEE _ICON _WHITE.png";
import { ContactProfileDetailsContext } from "../macrocomponents/ContactLayout";
import { useContext } from "react";
import { ThemeContext } from "../macrocomponents/RootLayout";

function LeftPanelBrandingLogo() {
  const { branding } = getSessionStore("lookUpResponse");
  const { isDarkMode: checked } = useContext(ThemeContext);
  const { isOpen } = useContext(ContactProfileDetailsContext);
  const { primaryLogo = "", secondaryLogo = "" } = branding ?? "";

  return (
    <div className="flex justify-center items-center">
      {isOpen ? (
        <div
          className={`flex justify-center items-center overflow-hidden ${
            isOpen ? "h-9rem rounded-3xl" : "!w-[4rem] !h-[4rem] rounded-lg"
          }`}
        >
          {primaryLogo ? (
            <img src={primaryLogo} className="h-full object-contain" alt="Logo" />
          ) : (
            <img
              src={checked ? IappDarkModeLogo : IappLightModeLogo}
              className="h-full object-contain"
              alt="Logo"
            />
          )}
        </div>
      ) : (
        <div
          className={`mb-2 py-3 flex justify-center items-center w-20 h-20 ${
            isOpen ? "w-20 h-20 rounded-3xl" : "!w-[3.5rem] !h-[3.5rem] rounded-lg"
          }`}
        >
          {secondaryLogo ? (
            <img
              src={secondaryLogo}
              className={`${isOpen ? "rounded-3xl" : "rounded-lg"} object-cover`}
              alt="Logo"
            />
          ) : (
            <img src={checked ? BEEICONWHITE : BEEICON} className="object-scale-down" alt="Logo" />
          )}
        </div>
      )}
    </div>
  );
}

export default LeftPanelBrandingLogo;
