import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetLoginState } from "app/redux";
import LogoutConfirmDialog from "./LogoutConfirmDialog";
import { ContactProfileDetailsContext } from "../macrocomponents/ContactLayout";
import { getSessionStore } from "app/utils/sessionStore";
import { Tooltip } from "primereact/tooltip";
import { THEME } from "app/utils/types";
import PrimeReact from "primereact/api";

function SignoutLeftPanel() {
  const { isOpen } = useContext(ContactProfileDetailsContext);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    const theme = getSessionStore("globalTheme");
    if (theme === THEME.DARK) {
      window.document.documentElement.classList.remove("dark");
      PrimeReact?.changeTheme?.(`lara-dark-blue`, `lara-light-blue`, "app-theme", () => {
        const allThemeLinks = document.querySelectorAll("link#app-theme");
        if (allThemeLinks.length > 1) {
          for (let i = 1; i < allThemeLinks.length; i++) {
            allThemeLinks[i].remove();
          }
        }
      });
    }
    localStorage.clear();
    sessionStorage.clear();
    dispatch(resetLoginState());
    navigate("/");
  };

  return (
    <>
      <LogoutConfirmDialog
        popUpOpen={popUpOpen}
        setPopUpOpen={setPopUpOpen}
        logoutHandle={logOut}
      />
      <div
        className={`custom-target-icon flex  p-2 mt-2   gap-2 items-center justify-center ${
          isOpen ? "flex-row rounded-lg" : "flex-col rounded-lg"
        } hover:cursor-pointer relative group hover:bg-gray-100 dark:hover:!bg-gray-800  `}
        onClick={() => setPopUpOpen(true)}
        data-pr-tooltip="Sign out"
      >
        <i
          className={`pi pi-sign-out !hover:text-dark-primary ${isOpen ? "" : "mb-2"}`}
          style={{ fontSize: "1rem" }}
        />
        <span
          className={`text-sm font-semibold !hover:text-dark-primary  ${
            isOpen ? "w-20" : "w-0 p-0 hidden"
          } transition-all overflow-hidden`}
        >
          Sign Out
        </span>
        {!isOpen && <Tooltip target=".custom-target-icon" className="tooltip-wrapper" />}
      </div>
    </>
  );
}

export default SignoutLeftPanel;
