import { ReactElement, ReactNode } from "react";
import { Dialog } from "primereact/dialog";

interface BlockConfirmationPopUpProps {
  visible: boolean;
  footerContent?: ReactElement;
  header: string | ReactNode;
  content: ReactNode;
  isHeadLess?: boolean;
  style?: any;
  className?: string;
  contentClassName?: string;
  onHide?: () => void;
}

const BlockPopup = ({
  visible,
  footerContent = <></>,
  header,
  content,
  isHeadLess = false,
  style = { width: "80vw" },
  className = "!w-[80vw]",
  contentClassName = "",
  onHide = () => {},
}: BlockConfirmationPopUpProps) => (
  <div className="card  block-pop-up flex justify-content-center">
    <Dialog
      header={!isHeadLess ? header : null}
      visible={visible}
      closable={false}
      style={style}
      className={`${className}   dark:text-dark-color`}
      onHide={onHide}
      footer={footerContent}
      keepInViewport
      contentClassName={`  dark:text-dark-color ${contentClassName}`}
      headerClassName="  dark:text-dark-color"
      maskClassName="  dark:text-dark-color"
    >
      <div className="m-0">{content}</div>
    </Dialog>
  </div>
);

export default BlockPopup;
