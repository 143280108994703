import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";
import { SEVERITY } from "app/utils/types";

export const TRIGGER_CREATE_BUYSELL_PROPOSAL_CONTACT_FETCH_REQUEST =
  "TRIGGER_CREATE_BUYSELL_PROPOSAL_CONTACT_FETCH_REQUEST";
export const TRIGGER_CREATE_BUYSELL_MASTER_DATA_PROVISION_FETCH_REQUEST =
  "TRIGGER_CREATE_BUYSELL_MASTER_DATA_PROVISION_FETCH_REQUEST";
export const TRIGGER_CREATE_BUYSELL_PROVISION_FETCH_REQUEST =
  "TRIGGER_CREATE_BUYSELL_PROVISION_FETCH_REQUEST";
export const TRIGGER_CREATE_BUYSELL_PROVISION_POST_REQUEST =
  "TRIGGER_CREATE_BUYSELL_PROVISION_POST_REQUEST";
export const TRIGGER_CREATE_BUYSELL_GET_PROPOSAL = "TRIGGER_CREATE_BUYSELL_GET_PROPOSAL";

interface ResponseType {
  data: any;
  error?: any;
  loading?: boolean;
}

interface BasicInfo {
  entityName: string;
  state: string;
}

interface Shareholder {
  id: string;
  name: string;
  ownershipPercentage: string;
  dob: string;
  gender: string;
  nicotineTobaccoUsage: string;
  occupation: string;
  participateInActivities: string[];
  bsProposalId: string;
}

interface PlanInfo {
  businessValuation: any;
  typeOfPlan: string;
  estimatedLastDate: any;
  needBusinessValuation: string;
  currentBuySellAgreement: string;
  updateBuySellAgreement: string;
  fullyFundedOnDeath: string;
  meetEstimatedValuation: string;
  lifeInsuranceType: string;
  fullyFundedOnDisability: string;
  note: string;
}

interface SuccessResponseBuySellProposalInfo {
  response: {
    basicDetails: BasicInfo;
    shareholderDetails: Shareholder[];
    planDetails: PlanInfo;
  };
}

interface SuccessResponseBuySellMasterDataProvision {
  response: {
    options: {
      id: string;
      name: string;
      payout: string;
      condition: string;
      modifiedAt: string;
      modifiedBy: string;
    }[];
  };
}

interface SuccessResponseBuySellProvision {
  provisions: {
    id: string;
    name: string;
    payout: string;
    condition: string;
    modifiedAt: string;
    modifiedBy: string;
    bsProposalId: string;
  }[];
}

interface SuccessResponseBuySellPostProvision {
  provisions: {
    id: string;
    name: string;
    payout: string;
    condition: string;
    modifiedAt: string;
    modifiedBy: string;
    bsProposalId: string;
  }[];
}

interface responseType {
  createBuySellProposalContactInfoFetchSuccess: SuccessResponseBuySellProposalInfo;
  createBuySellProposalContactInfoFetchError: ResponseType;
  createBuySellProposalMasterDataProvisionFetchSuccess: SuccessResponseBuySellMasterDataProvision;
  createBuySellProposalMasterDataProvisionFetchError: ResponseType;
  createBuySellProposalProvisionFetchSuccess: SuccessResponseBuySellProvision;
  createBuySellProposalProvisionFetchError: ResponseType;
  createBuySellProposalProvisionPostSuccess: SuccessResponseBuySellPostProvision;
  createBuySellProposalProvisionPostError: ResponseType;
}

const initialState: any = {
  createBuySellProposalContactInfoFetchSuccess: {
    loading: true,
    data: {
      basicDetails: {
        entityName: "",
        state: "",
      },
      shareholderDetails: [
        {
          id: "",
          name: "",
          ownershipPercentage: "",
          dob: "",
          gender: "",
          nicotineTobaccoUsage: "",
          occupation: "",
          participateInActivities: [],
          bsProposalId: "",
        },
      ],
      planDetails: {
        businessValuation: "",
        typeOfPlan: "",
        estimatedLastDate: "",
        needBusinessValuation: "",
        currentBuySellAgreement: "",
        updateBuySellAgreement: "",
        fullyFundedOnDeath: "",
        meetEstimatedValuation: "",
        lifeInsuranceType: "",
        fullyFundedOnDisability: "",
        note: "",
      },
    },
  },
  createBuySellProposalContactInfoFetchError: { data: {}, error: {} },
  createBuySellProposalMasterDataProvisionFetchSuccess: {
    loading: true,
    data: [],
  },
  createBuySellProposalMasterDataProvisionFetchError: { data: {}, error: {} },
  createBuySellProposalProvisionFetchSuccess: { loading: true, provisions: [] },
  createBuySellProposalProvisionFetchError: { data: {}, error: {} },
  createBuySellProposalProvisionPostSuccess: { loading: true },
  createBuySellProposalProvisionPostError: { data: {}, error: {} },
  getProposal: { loading: false, data: {}, error: {} },
  putBSProposal: { loading: false, data: {}, error: {} },
};

export const createBuySellProposalSlice = createSlice({
  name: "createBuySellProposal",
  initialState,
  reducers: {
    createBuySellProposalContactInfoFetchLoading: (state) => {
      state.createBuySellProposalContactInfoFetchSuccess = {
        ...state.createBuySellProposalContactInfoFetchSuccess,
        loading: true,
      };
    },
    createBuySellProposalContactInfoFetchSuccess: (state, action: PayloadAction<any>) => {
      state.createBuySellProposalContactInfoFetchSuccess = {
        loading: false,
        data: action.payload,
      };
    },
    createBuySellProposalContactInfoFetchError: (state, action: PayloadAction<responseType>) => {
      state.createBuySellProposalContactInfoFetchError = {
        loading: false,
        data: {},
        error: action.payload,
      };
    },
    resetBuySellProposalContactInfo: (state) => {
      state.createBuySellProposalContactInfoFetchSuccess = {
        loading: false,
        data: null,
      };
    },
    createBuySellProposalMasterDataProvisionFetchSuccess: (state, action: PayloadAction<any>) => {
      state.createBuySellProposalMasterDataProvisionFetchSuccess = {
        loading: false,
        data: action.payload,
      };
    },
    createBuySellProposalMasterDataProvisionFetchError: (
      state,
      action: PayloadAction<responseType>,
    ) => {
      state.createBuySellProposalMasterDataProvisionFetchError = {
        loading: false,
        data: {},
        error: action.payload,
      };
    },
    createBuySellProposalProvisionFetchSuccess: (state, action: PayloadAction<any>) => {
      state.createBuySellProposalProvisionFetchSuccess = {
        loading: false,
        ...action.payload,
      };
    },
    createBuySellProposalProvisionFetchError: (state, action: PayloadAction<responseType>) => {
      state.createBuySellProposalProvisionFetchError = {
        loading: false,
        data: {},
        error: action.payload,
      };
    },
    createBuySellProposalProvisionPostSuccess: (state, action: PayloadAction<any>) => {
      state.createBuySellProposalProvisionPostSuccess = {
        loading: false,
        status: SEVERITY.SUCCESS,
        ...action.payload,
      };
    },
    createBuySellProposalProvisionPostError: (state, action: PayloadAction<responseType>) => {
      state.createBuySellProposalProvisionPostError = {
        loading: false,
        data: {},
        error: action.payload,
      };
    },
    getProposalStart: (state) => {
      state.getProposal = { loading: true, data: {}, error: {} };
    },
    getProposalSuccess: (state, action: PayloadAction<any>) => {
      state.getProposal = { loading: false, data: action.payload, error: {} };
    },
    getProposalError: (state, action: PayloadAction<any>) => {
      state.getProposal = { loading: false, data: {}, error: action.payload };
    },
    putBSProposalStart: (state) => {
      state.putBSProposal = { loading: true, data: {}, error: {} };
    },
    putBSProposalSuccess: (state, action: PayloadAction<any>) => {
      state.putBSProposal = { loading: false, data: action.payload, error: {} };
    },
    putBSProposalError: (state, action: PayloadAction<any>) => {
      state.putBSProposal = { loading: false, data: {}, error: action.payload };
    },
    resetStateCreateBuySellProposal: () => initialState,
    resetStateCreateBuySellProposalErrors: (state) => {
      state.createBuySellProposalMasterDataProvisionFetchError = { data: {}, error: {} };
      state.createBuySellProposalProvisionFetchError = { data: {}, error: {} };
      state.createBuySellProposalProvisionPostError = { data: {}, error: {} };
    },
    resetStateGetProposal: (state) => {
      state.getProposal = { loading: false, data: {}, error: {} };
    },
    resetPutBSProposal: (state) => {
      state.putBSProposal = { loading: false, data: {}, error: {} };
    },
    resetStateBuySellProposalProvision: (state) => {
      state.createBuySellProposalProvisionPostSuccess = {
        loading: true,
      };
      state.createBuySellProposalProvisionPostError = { data: {}, error: {} };
    },
    resetStateBuySellProposalProvisionData: (state) => {
      state.createBuySellProposalProvisionFetchSuccess = {
        loading: true,
        provisions: [],
      };
      state.createBuySellProposalProvisionFetchError = { data: {}, error: {} };
      state.createBuySellProposalMasterDataProvisionFetchSuccess = {
        loading: true,
        data: [],
      };
      state.createBuySellProposalMasterDataProvisionFetchError = { data: {}, error: {} };
    },
  },
});

export const {
  createBuySellProposalContactInfoFetchSuccess,
  createBuySellProposalContactInfoFetchError,
  createBuySellProposalMasterDataProvisionFetchSuccess,
  createBuySellProposalMasterDataProvisionFetchError,
  createBuySellProposalProvisionFetchSuccess,
  createBuySellProposalProvisionFetchError,
  createBuySellProposalProvisionPostSuccess,
  createBuySellProposalProvisionPostError,
  getProposalStart,
  getProposalSuccess,
  getProposalError,
  putBSProposalStart,
  putBSProposalSuccess,
  putBSProposalError,
  resetPutBSProposal,
  resetStateGetProposal,
  resetStateCreateBuySellProposal,
  resetStateCreateBuySellProposalErrors,
  resetStateBuySellProposalProvision,
  resetStateBuySellProposalProvisionData,
  createBuySellProposalContactInfoFetchLoading,
  resetBuySellProposalContactInfo,
} = createBuySellProposalSlice.actions;

export const getCreateBuySellProposal = (state: RootState) => state.createBuySellProposalData;

export default createBuySellProposalSlice.reducer;
