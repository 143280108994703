import { useContext } from "react";
import { Skeleton } from "primereact/skeleton";
import { useNavigate } from "react-router-dom";
import { ContactProfileDetailsContext } from "../macrocomponents/ContactLayout";
import ProfileSVG from "../../assets/profileAvatar.svg";
import { Button } from "primereact/button";

function LeftPanelImage() {
  const { contactProfilePic, isOpen } = useContext(ContactProfileDetailsContext);
  const navigate = useNavigate();
  const onSettingsClick = () => navigate("agent/contact/profile");
  return (
    <div className="pt-6 pb-1 flex justify-center items-center ">
      <div
        className={`${
          isOpen ? "w-20 h-20 rounded-3xl shadow-4" : "!w-[3.5rem] !h-[3.5rem] rounded-lg"
        }  border-[1px] border-gray-100  relative`}
      >
        {contactProfilePic || ProfileSVG ? (
          <img
            src={contactProfilePic || ProfileSVG}
            className={`w-full h-full  object-cover ${isOpen ? "rounded-3xl" : "rounded-lg"}`}
            alt="Profile Pic"
          />
        ) : (
          <Skeleton width="5rem" height="5rem" borderRadius="8px" className="dark:bg-blue-1100" />
        )}
        <div
          className="absolute flex justify-center items-center shadow-lg overflow-hidden dark:border-[1px] dark:border-colorBoder rounded-full hover:cursor-pointer light:hover:bg-gray-300 "
          style={{
            width: isOpen ? "2rem" : "1.5rem",
            height: isOpen ? "2rem" : "1.5rem",
            right: isOpen ? "-1rem" : "-.8rem",
            bottom: "-0.5rem",
          }}
          onClick={onSettingsClick}
        >
          <Button
            icon="pi pi-cog"
            className="hover:rotate-[10deg] bg-gray-500 dark:!bg-dark-secondary hover:dark:!bg-dark-secondary text-[#485056] dark:!text-white h-full w-full border-0"
            tooltip="Settings"
            tooltipOptions={{
              className: "tooltip-wrapper",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default LeftPanelImage;
