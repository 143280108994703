import { Navigate } from "react-router-dom";
import { getObjectFromLocalStore } from "../app/utils/localStore";
import { isEmpty } from "ramda";
import PrimeReact from "primereact/api";
// NOTE call this auth from redux store

const PrivateRoute = (props: { children: JSX.Element }) => {
  const { children } = props;
  const userAccess = getObjectFromLocalStore("useraccess");

  if (isEmpty(userAccess)) {
    const themeLink = (document.getElementById("app-theme") as HTMLLinkElement)?.href;
    const splittedTheme = themeLink.split("/");
    const currentTheme = splittedTheme?.[(splittedTheme?.length ?? 2) - 2];
    const isCurrentThemeDark = currentTheme.includes("dark");

    if (isCurrentThemeDark) {
      window.document.documentElement.classList.remove("dark");
      PrimeReact?.changeTheme?.(`lara-dark-blue`, `lara-light-blue`, "app-theme", () => {
        const allThemeLinks = document.querySelectorAll("link#app-theme");
        if (allThemeLinks.length > 1) {
          for (let i = 1; i < allThemeLinks.length; i++) {
            allThemeLinks[i].remove();
          }
        }
      });
    }
    // NOTE not logged in so redirect to login page with the return url
    return <Navigate to="/" />;
  }

  return children;
};

export default PrivateRoute;
