import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { clone } from "ramda";

export const GET_KEY_PERSON_CONTACT_INFO = "GET_KEY_PERSON_CONTACT_INFO";
export const GET_KEY_PERSON_PROPOSAL_INFO = "GET_KEY_PERSON_PROPOSAL_INFO";
export const UPDATE_ROW_KEY_PERSON_PROPOSAL_INFO = "UPDATE_ROW_KEY_PERSON_PROPOSAL_INFO";

export interface KP_ROW_SCHEMA {
  planYear: number;
  deathBenefit: number;
  criticalIllnessBenefit: number;
  disabilityBenefit: number;
  annualLifePremium: number;
  annualDisabilityPremium: number;
  totalAnnualPremium: number;
  annualAdminFees: number;
  planOriginationFees: number;
  totalPlanCost: number;
}

interface COMMON_TYPE {
  data: null | any;
  error: null | any;
  loading: boolean;
}

interface INITIAL_STATE {
  contactInfo: COMMON_TYPE;
  proposalInfo: COMMON_TYPE;
}

const initialState: INITIAL_STATE = {
  contactInfo: { data: null, error: null, loading: false },
  proposalInfo: { data: null, error: null, loading: false },
};

export const createKeyPersonProposalSlice = createSlice({
  name: "createKeyPersonProposal",
  initialState,
  reducers: {
    createKeyPersonProposalContactInfoLoading: (state) => {
      state.contactInfo = {
        loading: true,
        data: null,
        error: null,
      };
    },
    createKeyPersonProposalContactInfoFetchSuccess: (state, action: PayloadAction<any>) => {
      state.contactInfo = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    createKeyPersonProposalContactInfoFetchError: (state, action: PayloadAction<any>) => {
      state.contactInfo = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    createKPProposalInfoLoading: (state) => {
      state.proposalInfo = {
        loading: true,
        data: null,
        error: null,
      };
    },
    createKPProposalInfoSuccess: (state, action: PayloadAction<any>) => {
      state.proposalInfo = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    createKPProposalInfoError: (state, action: PayloadAction<any>) => {
      state.proposalInfo = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    KPUpdateProposalRowData: (
      state,
      action: PayloadAction<{
        data: KP_ROW_SCHEMA;
      }>,
    ) => {
      const idx = state.proposalInfo.data?.rows?.findIndex(
        (el: KP_ROW_SCHEMA) => el.planYear === action.payload.data?.planYear,
      );

      const updatedRows = clone(state.proposalInfo.data?.rows);

      updatedRows[idx] = action.payload.data;
      updatedRows[idx].isChanged = true;

      state.proposalInfo = {
        ...state.proposalInfo,
        data: {
          ...state.proposalInfo.data,
          rows: updatedRows,
        },
      };
    },
    resetKPProposalInfo: (state) => {
      state.proposalInfo = {
        loading: false,
        data: null,
        error: null,
      };
    },
    resetKeyPersonProposalSlice: () => initialState,
  },
});

export const {
  createKeyPersonProposalContactInfoFetchSuccess,
  createKeyPersonProposalContactInfoFetchError,
  createKeyPersonProposalContactInfoLoading,
  createKPProposalInfoError,
  createKPProposalInfoLoading,
  createKPProposalInfoSuccess,
  resetKPProposalInfo,
  KPUpdateProposalRowData,
} = createKeyPersonProposalSlice.actions;

export const getCreateKeyPersonProposal = (state: RootState) =>
  state.createKeyPersonProposalReducer;

export default createKeyPersonProposalSlice.reducer;
