import { FC, useEffect, useRef, useState, useMemo } from "react";
import { RootState } from "app/store";
import { Sidebar } from "primereact/sidebar";
import { Divider } from "primereact/divider";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { SidebarItem, GroupItems, SidebarSubItem, Branding } from "../../../Data/types/Dashboard";
import "../../../css/Sidebar.css";
import { Button } from "primereact/button";
import { TRIGGER_STORE_DASHBOARD_DATA, getDashboardData } from "app/redux/features/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "primereact/badge";
import { THEME, USERTYPE } from "app/utils/types";
import IAPPLogoDarkIcon from "../../../assets/IAPPLogoDarkIcon.svg";
import BlockPopup from "app/uicomponents/microcomponents/BlockPopup";
import { useTranslation } from "react-i18next";
import {
  TRIGGER_STORE_IS_FORM_ACTIVE_DATA,
  getIsFormActiveData,
} from "app/redux/features/IsFormActive";
import {
  getObjectFromSessionStore,
  getSessionStore,
  removeSessionStore,
  setObjectInSessionStore,
} from "app/utils/sessionStore";
import { isEmpty } from "ramda";
import {
  GET_AGENT_PROFILE,
  TRIGGER_SWITCH_USER,
  agentProfileState,
  getAgentBranding,
  setGoingToSAManagerProfile,
  setIsComingFromURL,
  AGENT_PROFILE_LOOKUP,
  resetBuySellRequestProposalData,
  resetUserInviteDetailsState,
  resetInsuredDefaultvalue,
  resetAgentContacts,
  setActiveIndex,
} from "app/redux";
import { getIsAgencyManagerActiveData } from "app/redux/features/isAgencyActive";
import { Image } from "primereact/image";
import { Dropdown } from "primereact/dropdown";
import { getAuthSwitch, resetAuthSwitchData } from "app/redux/features/auth-switch";
import { getObjectFromLocalStore, removeLocalStore, setLocalStore } from "app/utils/localStore";
import plansSVG from "../../../assets/plansDark.svg";
import plansLightSVG from "../../../assets/plansLight.svg";
import pendingSVG from "../../../assets/pendingDark.svg";
import pendingLightSVG from "../../../assets/pendingLight.svg";
import resourcesSVG from "../../../assets/resourcesDark.svg";
import resourcesLightSVG from "../../../assets/resourcesLight.svg";

import { makeRequest } from "app/utils/makeRequest";

const DashboardLeftPanel: FC<{
  fields: SidebarItem[];
  branding: Branding;
  badgeCount?: { [key: string]: number };
  assistantAgentList?: any;
}> = ({ fields, branding, badgeCount, assistantAgentList }) => {
  const [visible, setVisible] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [menu, setMenu] = useState<SidebarItem[]>([]);
  const [menuCurrentId, setMenuCurrentId] = useState<string>();
  const [nextRouterUrl, setNextRouterUrl] = useState<string>("");
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { isFormActive } = useSelector(getIsFormActiveData);
  const [isBlockVisible, setIsBlockVisible] = useState(true);
  const scrollRef = useRef<HTMLUListElement | null>(null);
  const [popUpDisplay, setPopupDisplay] = useState<boolean>(false);
  const [getAgencyBranding, setAgencyBranding] = useState<any>();
  const agencyBranding = getObjectFromSessionStore("agencyBrandings");
  const { data: agentProfileData } = useSelector(agentProfileState);
  const { data } = useSelector(getDashboardData);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAgencyManagerActive } = useSelector(getIsAgencyManagerActiveData);
  const { data: agencyProfileData } = useSelector(agentProfileState);
  const [selectedValue, setSelectedValue] = useState(null);
  const { postAuthSwitchSuccess } = useSelector(getAuthSwitch);
  const assistantAgentObject = getObjectFromLocalStore("assistantAgentUserAccess");
  const userAccess = getObjectFromLocalStore("useraccess");
  const globalTheme = getSessionStore("globalTheme");
  // const selectedValue = getSessionStore("selectedValue");
  const [themeVal, setThemeVal] = useState(globalTheme);
  const [primaryLogo, setPrimaryLogo] = useState("");
  const [secondaryLogo, setSecondaryLogo] = useState("");
  const { settingThemeData, activeBrandingSuccess, dataBranding } = useSelector(getAgentBranding);
  const assistantAgentListNames = assistantAgentList.map((item: any) => ({
    label: item?.name,
    value: item?.id,
  }));
  const globalFontSize = getSessionStore("globalFontSize");
  const assistantAgentUserData = getObjectFromLocalStore("assistantAgentUserAccess");
  const agentProfile = getObjectFromSessionStore("agentlookUpResponse");
  const agentLookUpResponse = useSelector((state: RootState) => state?.agentProfileLookup);

  const [agentBrandingData, setAgentBrandingData] = useState(null);
  useEffect(() => {
    setThemeVal(globalTheme);
  }, [globalFontSize]);

  useEffect(() => {
    setThemeVal(globalTheme);
  }, [settingThemeData]);

  function initializeMenu(menuList: SidebarItem[], path: string) {
    const countForwardSymbol = (path.match(/\//g) || []).length;
    const pathArr = path.substring(1).split("/");
    return menuList.map((menuItem) => {
      const group = menuItem.groupItems.map((grpItem) => {
        let updatedGroupItem = {
          ...grpItem,
          isActive:
            grpItem.label === "Contacts" && pathArr.length > 1
              ? false
              : pathArr.includes(grpItem.link.substring(1)),
        };
        if (grpItem.groupItems?.length) {
          updatedGroupItem = {
            ...updatedGroupItem,
            groupItems: grpItem.groupItems.map((subMenuItem: SidebarSubItem) => {
              const customLabel =
                subMenuItem.label === "Requests For Proposals"
                  ? "Requests"
                  : subMenuItem.label === "Pending"
                  ? "Needs Processing"
                  : subMenuItem.label;
              return {
                ...subMenuItem,
                label: customLabel,
                isActive:
                  pathArr.findIndex(
                    (name) => name === `${customLabel.replace(/\s/g, "")}`.toLowerCase(),
                  ) > 0 && countForwardSymbol > 1,
              };
            }),
          };
        }
        return updatedGroupItem;
      });
      return { ...menuItem, groupItems: group };
    });
  }
  useEffect(() => {
    if (!isEmpty(postAuthSwitchSuccess?.data)) {
      const updateDataList = { ...postAuthSwitchSuccess.data };
      const filteredData = assistantAgentList.filter((item: any) => item.id === selectedValue);
      updateDataList.selectedAssistantAgentOption = selectedValue;
      updateDataList.viewCommission = filteredData?.[0]?.viewCommission;
      updateDataList.hasAdviserRole = filteredData?.[0]?.hasAdviserRole;
      setLocalStore("assistantAgentUserAccess", updateDataList);
      navigate("/proposal/drafts");
      dispatch(resetAuthSwitchData());
      dispatch({
        type: AGENT_PROFILE_LOOKUP,
        payload: {
          address: "lookupAfterAgent",
        },
      });
    }
  }, [postAuthSwitchSuccess?.data]);

  useEffect(() => {
    if (!isEmpty(agencyBranding) && !getAgencyBranding) {
      setAgencyBranding(agencyBranding);
    }
  }, [agencyBranding, agentProfileData]);

  useEffect(() => {
    setIsCollapsed(data);
  }, []);

  const agencyBrandingData = getObjectFromSessionStore("activeBrandingUser");
  const hasAgencyBrandingData = agencyBrandingData?.primaryLogo;

  const agentCurrentBranding = sessionStorage.getItem("agentCurrentBranding");
  useEffect(() => {
    if (
      isAgencyManagerActive &&
      !hasAgencyBrandingData &&
      agencyProfileData?.agency?.currentBranding?.length > 0
    ) {
      let currAgency = agencyProfileData?.agency;
      if (agencyProfileData?.agency?.currentBranding === "parentAgency") {
        currAgency = agencyProfileData?.agency?.parentAgency;
      }
      setObjectInSessionStore("activeBrandingUser", {
        activeUserType: agencyProfileData?.agency?.currentBranding,
        primaryLogo:
          agencyProfileData?.agency?.currentBranding === "iapp"
            ? process.env.REACT_APP_ENVIRONMENT === "PROD"
              ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_color.png`
              : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_color.png`
            : currAgency?.AgencyBrandings?.[0]?.primaryLogo ||
              (process.env.REACT_APP_ENVIRONMENT === "PROD"
                ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_color.png`
                : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_color.png`),
        secondaryLogo:
          agencyProfileData?.agency?.currentBranding === "iapp"
            ? process.env.REACT_APP_ENVIRONMENT === "PROD"
              ? `${process.env.REACT_APP_ASSET_URL_PROD}/bee_icon.png`
              : `${process.env.REACT_APP_ASSET_URL_DEV}/BEE+ICON.png`
            : currAgency?.AgencyBrandings?.[0]?.secondaryLogo,
      });
      dispatch({
        type: GET_AGENT_PROFILE,
        payload: {
          address: "agencyProfile",
        },
      });
    }
  }, [agencyProfileData]);

  const userType = getSessionStore("type");
  const agentProfileId =
    userAccess.userType === USERTYPE.ADMIN && userType === "agency"
      ? getSessionStore("managerId")
      : userAccess.userType === USERTYPE.ADMIN && userType === "subAgency"
      ? getSessionStore("subAgencyManagerId")
      : userAccess.userType === USERTYPE.ADMIN || pathname === "/agent/branding"
      ? getSessionStore("agentProfileId")
      : assistantAgentObject?.selectedAssistantAgentOption
      ? assistantAgentObject?.selectedAssistantAgentOption
      : "";

  useEffect(() => {
    if (!isAgencyManagerActive) {
      makeRequest("allBrandingDetails")
        .get(
          userAccess.userType === USERTYPE.ADMIN || pathname === "/agent/branding"
            ? `userId=${agentProfileId}`
            : "",
          false,
          userAccess.userType === USERTYPE.ADMIN || pathname === "/agent/branding",
        )
        .then((res) => {
          setAgentBrandingData(
            res.data?.data?.brandingData?.find(
              (dt: any) => dt?.userType === res.data?.data?.currentBranding,
            ),
          );
          setObjectInSessionStore("activeBrandingUser", {
            activeUserType: res.data?.data?.currentBranding,
          });
        });
    } else {
      setAgentBrandingData(null);
    }
  }, [isAgencyManagerActive, activeBrandingSuccess, dataBranding]);

  function handleMenuSelection(
    arr: SidebarItem[] | GroupItems[],
    itemId: string,
    isParentActive?: boolean,
  ): SidebarItem[] {
    return arr.map(
      (item, index) =>
        ({
          ...item,
          isActive:
            item.id === itemId ||
            item.groupItems?.some((child) => child.isActive) ||
            (isParentActive && index === 0),
          ...(item.groupItems && {
            groupItems: handleMenuSelection(item.groupItems as GroupItems[], itemId),
          }),
        } as SidebarItem),
    );
  }
  const handleButtonClick = () => {
    setIsBlockVisible((prevState) => !prevState);
  };

  useEffect(() => {
    let interVal: NodeJS.Timeout;
    if (pathname.startsWith("/contacts")) {
      const menuItem = menu.find(
        (el) => el.groupItems.findIndex((item) => item.label === "Contacts") >= 0,
      );
      const subMenuItem = menuItem?.groupItems.find((item) => item.label === "Contacts");
      if (!subMenuItem?.isActive) {
        interVal = setTimeout(() => {
          const updatedMenu = handleMenuSelection(menu, subMenuItem?.id as string);
          setMenu(updatedMenu);
        }, 10);
      }
    }

    return () => {
      clearTimeout(interVal);
    };
  }, [pathname, menu]);

  const handleformActiveCondition = () => {
    dispatch({ type: TRIGGER_STORE_IS_FORM_ACTIVE_DATA, payload: { body: false } });
    // start  of clear propoasl request forms data on click
    dispatch(resetBuySellRequestProposalData());
    dispatch(resetUserInviteDetailsState());
    dispatch(resetInsuredDefaultvalue());
    removeSessionStore("insuredPlanDetails");
    removeSessionStore("keyPersonDetails");
    removeSessionStore("insuredPlanDetailsComplete");
    dispatch(resetAgentContacts());
    dispatch(setActiveIndex(2));
    // end of clear propoasl request forms data on click/////////////
    setPopupDisplay(false);
    const updatedMenu = handleMenuSelection(menu, menuCurrentId as string);
    setMenu(updatedMenu);
    if (selectedValue !== null) {
      removeLocalStore("assistantAgentUserAccess");
      dispatch({
        type: TRIGGER_SWITCH_USER,
        payload: {
          address: "authSwitchUser",
          key: selectedValue,
          body: {},
          requestParams: true,
          queryParams: false,
        },
      });
    } else {
      navigate(nextRouterUrl as string);
    }
  };
  const handleKeepEditing = () => {
    setPopupDisplay(false);
  };

  useEffect(() => {
    if (!isEmpty(selectedValue) && selectedValue !== null) {
      if (!isFormActive) {
        removeLocalStore("assistantAgentUserAccess");
        dispatch({
          type: TRIGGER_SWITCH_USER,
          payload: {
            address: "authSwitchUser",
            key: selectedValue,
            body: {},
            requestParams: true,
            queryParams: false,
          },
        });
      } else {
        setPopupDisplay(true);
      }
    }
  }, [selectedValue]);

  const confirmationPopUpfooterContent = (
    <div>
      <Button
        label={t("general.button.keepEditing") as string}
        onClick={handleKeepEditing}
        className="p-button-text border-gray-500 text-gray-500"
      />
      <Button
        label={t("general.button.discardChanges") as string}
        className="bg-red-500 border-red-500"
        onClick={handleformActiveCondition}
        autoFocus
      />
    </div>
  );
  const header = t("general.discardTitle");
  const content = t("general.discardSubTitle");

  function updateMenu(menuItemId: string) {
    const updatedMenu = handleMenuSelection(menu, menuItemId as string);
    setMenu(updatedMenu);
  }

  const toggleSidebar = async () => {
    setIsCollapsed((prev) => !prev);
    await dispatch({ type: TRIGGER_STORE_DASHBOARD_DATA, payload: { body: !isCollapsed } });
  };

  useMemo(() => {
    const updatedMenu = initializeMenu(fields, pathname);
    setMenu(updatedMenu);
  }, [fields, pathname]);

  const scrollToActiveItem = () => {
    const activeItemElement = scrollRef.current?.querySelector(".bg-layoutBlue");
    if (activeItemElement) {
      activeItemElement.scrollIntoView({ behavior: "auto", block: "center" });
    }
  };

  const handleScrollRef = (node: HTMLUListElement | null) => {
    scrollRef.current = node;
    scrollToActiveItem();
  };

  const getThemeBasedIAPPBranding = () => {
    if (isCollapsed) {
      return globalTheme === THEME.DARK
        ? IAPPLogoDarkIcon
        : process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? `${process.env.REACT_APP_ASSET_URL_PROD}/bee_icon.png`
        : `${process.env.REACT_APP_ASSET_URL_DEV}/BEE+ICON.png`;
    }
    return globalTheme === THEME.DARK
      ? process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_white.png`
        : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_white.png`
      : process.env.REACT_APP_ENVIRONMENT === "PROD"
      ? `${process.env.REACT_APP_ASSET_URL_PROD}/iapp_color.png`
      : `${process.env.REACT_APP_ASSET_URL_DEV}/iapp_color.png`;
  };

  useEffect(() => {
    if (userAccess?.isAssistant && !isEmpty(assistantAgentUserData)) {
      setPrimaryLogo(
        agentLookUpResponse?.data?.branding?.primaryLogo ||
          agentProfile?.branding?.primaryLogo ||
          "",
      );
      setSecondaryLogo(
        agentLookUpResponse?.data?.branding?.secondaryLogo ||
          agentProfile?.branding?.secondaryLogo ||
          "",
      );
    }
  }, [agentProfile, agentLookUpResponse]);

  const getBrandingImage = () => {
    if (userAccess?.isAssistant && !isEmpty(assistantAgentUserData) && primaryLogo !== "") {
      if (!isCollapsed) {
        return primaryLogo;
      }
      return secondaryLogo;
    }

    // if user is agent then show default iapp branding
    if (userAccess?.userType === USERTYPE.AGENT) {
      return getThemeBasedIAPPBranding();
    }
    if (userAccess?.userType !== USERTYPE.AGENT && userAccess?.userType !== USERTYPE.CONTACT) {
      return getThemeBasedIAPPBranding();
    }
    if (isAgencyManagerActive) {
      if (
        agencyProfileData?.agency?.currentBranding === "parentAgency" &&
        agencyProfileData?.agency?.parentAgency?.AgencyBrandings?.[0]?.[
          !isCollapsed ? "primaryLogo" : "secondaryLogo"
        ]
      ) {
        return agencyProfileData?.agency?.parentAgency?.AgencyBrandings?.[0]?.[
          !isCollapsed ? "primaryLogo" : "secondaryLogo"
        ];
      }
      if (
        agencyProfileData?.agency?.AgencyBrandings?.[0]?.[
          !isCollapsed ? "primaryLogo" : "secondaryLogo"
        ]
      ) {
        return agencyProfileData?.agency?.currentBranding === "iapp"
          ? getThemeBasedIAPPBranding()
          : agentProfileData?.agency?.AgencyBrandings?.[0]?.[
              !isCollapsed ? "primaryLogo" : "secondaryLogo"
            ];
      }
      return agencyBrandingData?.[!isCollapsed ? "primaryLogo" : "secondaryLogo"];
    }

    const image =
      agentCurrentBranding === "iapp"
        ? getThemeBasedIAPPBranding()
        : agentBrandingData
        ? agentBrandingData?.[!isCollapsed ? "primaryLogo" : "secondaryLogo"]
        : branding?.[!isCollapsed ? "primaryLogo" : "secondaryLogo"]?.length
        ? branding?.[!isCollapsed ? "primaryLogo" : "secondaryLogo"]
        : getThemeBasedIAPPBranding();

    return image;
  };

  return (
    <>
      <Sidebar
        visible={visible}
        closeOnEscape={false}
        showCloseIcon={false}
        modal={false}
        className="  dark:!text-dark-color"
        dismissable={false}
        onHide={() => setVisible(false)}
        appendTo={document.getElementById("sidebar")}
      >
        <div className=" ">
          <div className="branding">
            {isCollapsed ? (
              <img
                key={`${isAgencyManagerActive}${(agentBrandingData as any)?.id}`}
                src={getBrandingImage()}
                className="pb-3 rounded-xl"
                alt=""
              />
            ) : (
              <div className="flex justify-center">
                <img
                  key={`${isAgencyManagerActive}${(agentBrandingData as any)?.id}`}
                  src={getBrandingImage()}
                  className=" h-20 rounded-xl"
                  alt=""
                />
              </div>
            )}
          </div>
          <div className="relative">
            <Button
              icon={isCollapsed ? "pi pi-angle-double-right" : "pi pi-angle-double-left"}
              onClick={toggleSidebar}
              className="sidebar-button absolute top-0 right-[-30px] bg-gray-100 dark:!bg-gray-800 dark:text-light-secondary rounded-rounded p-1 text-base text-black border-gray-chip border-[1px] hover:border-gray-chip"
            />
          </div>
          <ul ref={handleScrollRef} className="sidebarList leftPanelUl">
            {menu && (
              <li>
                {menu.map((group: SidebarItem) => (
                  <ul key={`${group?.id}-${group.groupName}`} className="leftPanelUl">
                    <li>
                      <ul className="leftPanelUl">
                        {group.groupItems?.map((menuItem: GroupItems) => (
                          <li key={`${menuItem?.id}-${menuItem.groupName}`}>
                            <div
                              className={`${isCollapsed ? "flex items-center justify-center" : ""}`}
                            >
                              <a
                                href={`${process.env.PUBLIC_URL}/#${
                                  menuItem?.groupItems?.length
                                    ? `${menuItem?.link}/${menuItem?.groupItems[0]?.label.replace(
                                        /\s/g,
                                        "",
                                      )}`.toLowerCase()
                                    : menuItem?.link
                                }`}
                                className={`p-3 block rounded-lg cursor-pointer ${
                                  !menuItem?.isActive && "hover:bg-gray-200 dark:hover:!bg-gray-800"
                                } ${
                                  menuItem?.isActive && menuItem?.groupItems
                                    ? "bg-layoutBlue text-white nav-active"
                                    : menuItem.isActive
                                    ? "bg-layoutBlue text-white nav-active"
                                    : ""
                                  // : "bg-white hover:bg-gray-200"
                                }`}
                                onClick={(e) => {
                                  if (isFormActive === false) {
                                    updateMenu(menuItem.id);
                                    dispatch(setGoingToSAManagerProfile(false));
                                    dispatch(setIsComingFromURL(""));
                                  } else {
                                    e.preventDefault();
                                    setPopupDisplay(true);
                                    setMenuCurrentId(menuItem.id);
                                    setNextRouterUrl(
                                      `${
                                        menuItem?.groupItems?.length
                                          ? `${
                                              menuItem?.link
                                            }/${menuItem?.groupItems[0]?.label.replace(
                                              /\s/g,
                                              "",
                                            )}`.toLowerCase()
                                          : menuItem?.link
                                      }`,
                                    );
                                  }
                                }}
                              >
                                {menuItem?.label === "Plans" ||
                                menuItem?.label === "Pending" ||
                                menuItem?.label === "Resources" ? (
                                  <>
                                    <div className="flex justify-between menu-div">
                                      <div className="flex menu-div">
                                        <div
                                          className={`w-[25px] pr-2 menu-div ${
                                            globalFontSize === "extraLarge"
                                              ? "pt-2"
                                              : globalFontSize === "large"
                                              ? "pt-[6px]"
                                              : "pt-1"
                                          } `}
                                        >
                                          <Image
                                            src={
                                              menuItem?.isActive &&
                                              menuItem?.groupItems &&
                                              menuItem?.label === "Plans"
                                                ? plansLightSVG
                                                : menuItem?.isActive &&
                                                  menuItem?.label === "Pending"
                                                ? pendingLightSVG
                                                : menuItem?.isActive && menuItem?.label === "Plans"
                                                ? plansLightSVG
                                                : menuItem?.label === "Plans" && themeVal === "dark"
                                                ? plansLightSVG
                                                : menuItem?.label === "Plans" &&
                                                  themeVal === "light"
                                                ? plansSVG
                                                : menuItem?.label === "Pending" &&
                                                  themeVal === "dark"
                                                ? pendingLightSVG
                                                : menuItem?.label === "Pending" &&
                                                  themeVal === "light"
                                                ? pendingSVG
                                                : themeVal === "dark"
                                                ? resourcesLightSVG
                                                : resourcesSVG
                                            }
                                            className="w-3 h-1rem"
                                          />
                                        </div>
                                        {!isCollapsed && (
                                          <span
                                            className={`${
                                              menuItem?.isActive && menuItem?.groupItems
                                                ? "!text-white"
                                                : ""
                                            }`}
                                          >
                                            {menuItem?.label}
                                          </span>
                                        )}
                                      </div>
                                      {badgeCount && menuItem.label in badgeCount && (
                                        <span>
                                          {!isCollapsed &&
                                            badgeCount &&
                                            menuItem.label in badgeCount &&
                                            badgeCount?.[menuItem.label] > 0 && (
                                              <span className="mr-1 !text-sm">
                                                <Badge
                                                  // value={badgeCount?.[menuItem.label]}
                                                  severity="info"
                                                  // pt={{
                                                  //   root: {
                                                  //     className: `${
                                                  //       badgeCount?.[menuItem.label] <= 9
                                                  //         ? "px-3"
                                                  //         : badgeCount?.[menuItem.label] <= 99
                                                  //         ? "px-[12px]"
                                                  //         : ""
                                                  //     } border-round-md`,
                                                  //   },
                                                  // }}
                                                />
                                              </span>
                                            )}
                                          {!isCollapsed && menuItem?.groupItems?.length && (
                                            <button
                                              className="float-right !w-8 h-8 rounded-full hover:bg-gray-100 hover:text-blue-500"
                                              type="button"
                                              onClick={handleButtonClick}
                                            >
                                              <i
                                                aria-label="up/down"
                                                className={`pi ${
                                                  menuItem.isActive && isBlockVisible
                                                    ? "pi-angle-up"
                                                    : "pi-angle-down"
                                                }`}
                                              />
                                            </button>
                                          )}
                                        </span>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <span className="flex justify-between">
                                    <span>
                                      <i className={`pi ${menuItem?.icon} pr-2`} />
                                      {!isCollapsed && <span>{menuItem?.label}</span>}
                                    </span>
                                    {!isCollapsed &&
                                      badgeCount &&
                                      menuItem.label in badgeCount &&
                                      badgeCount?.[menuItem.label] > 0 && (
                                        <span>
                                          <span className="mr-1 !text-sm">
                                            <Badge
                                              // value={badgeCount?.[menuItem.label]}
                                              severity="info"
                                              // pt={{
                                              //   root: {
                                              //     className: `${
                                              //       badgeCount?.[menuItem.label] <= 9
                                              //         ? "px-3"
                                              //         : badgeCount?.[menuItem.label] <= 99
                                              //         ? "px-[12px]"
                                              //         : ""
                                              //     } border-round-md`,
                                              //   },
                                              // }}
                                            />
                                          </span>
                                          {!isCollapsed && menuItem?.groupItems?.length && (
                                            <button
                                              className="!w-8 h-8 rounded-full hover:bg-gray-100 hover:text-blue-500"
                                              type="button"
                                              onClick={handleButtonClick}
                                            >
                                              <i
                                                aria-label="up/down"
                                                className={`pi ${
                                                  menuItem.isActive && isBlockVisible
                                                    ? "pi-angle-up"
                                                    : "pi-angle-down"
                                                }`}
                                              />
                                            </button>
                                          )}
                                        </span>
                                      )}
                                  </span>
                                )}
                              </a>
                            </div>
                            {!isCollapsed &&
                              menuItem?.groupItems?.length &&
                              (menuItem?.isActive ||
                                menuItem?.groupItems?.find((item) => item.isActive)) &&
                              isBlockVisible && (
                                <ul className="ml-4 mt-2 border-l border-gray-300 leftPanelUl">
                                  {menuItem.groupItems.map((submenuItem: SidebarSubItem) => (
                                    <li
                                      key={`${submenuItem?.id}-${submenuItem.label}`} // {submenuItem.id}
                                      onClick={(e) => {
                                        if (isFormActive === false) {
                                          updateMenu(menuItem.id);
                                        } else {
                                          e.preventDefault();
                                          setPopupDisplay(true);
                                          setMenuCurrentId(menuItem.id);
                                          setNextRouterUrl(
                                            `${`${menuItem.link}/${submenuItem.label.replace(
                                              /\s/g,
                                              "",
                                            )}`.toLowerCase()}`,
                                          );
                                        }
                                        // updateMenu(submenuItem.id);
                                      }}
                                    >
                                      <Link
                                        className={`p-3 block rounded-lg cursor-pointer ml-2 ${
                                          !submenuItem?.isActive &&
                                          "hover:text-gray-700 dark:hover:!text-gray-500"
                                        } ${
                                          submenuItem.isActive
                                            ? "text-blue-500 dark:!text-blue-400"
                                            : ""
                                          // : "bg-white hover:bg-gray-200"
                                        }`}
                                        to={
                                          isFormActive === false
                                            ? `${menuItem.link}/${submenuItem.label.replace(
                                                /\s/g,
                                                "",
                                              )}`.toLowerCase()
                                            : ""
                                        }
                                      >
                                        <p>
                                          {submenuItem.label}
                                          {badgeCount &&
                                            submenuItem.label in badgeCount &&
                                            badgeCount?.[submenuItem.label] > 0 && (
                                              <span
                                                className={`float-right items-center ${
                                                  submenuItem.isActive
                                                    ? "text-layoutBlue"
                                                    : "text-white"
                                                } `}
                                              >
                                                <Badge
                                                  value={badgeCount?.[submenuItem.label]}
                                                  severity="info"
                                                  pt={{
                                                    root: {
                                                      className: `${
                                                        badgeCount?.[submenuItem.label] <= 9
                                                          ? "px-3"
                                                          : badgeCount?.[submenuItem.label] <= 99
                                                          ? "px-[12px]"
                                                          : ""
                                                      } border-round-md`,
                                                    },
                                                  }}
                                                />
                                              </span>
                                            )}
                                        </p>
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                          </li>
                        ))}
                      </ul>
                    </li>
                    {group.groupName === "connection" ? null : (
                      <li>
                        <Divider className="custom-divider" />
                      </li>
                    )}
                  </ul>
                ))}
              </li>
            )}
          </ul>
          <div>
            {userAccess?.isAssistant && (
              <div className="absolute bottom-0 w-full left-0 pt-2 bg-white   dark:!text-dark-color">
                <label htmlFor="assignAssistantAgent">Select an advisor to work for:</label>
                <Dropdown
                  inputId="assignAssistantAgent"
                  value={selectedValue || assistantAgentObject?.selectedAssistantAgentOption}
                  placeholder="Select an advisor to work"
                  options={assistantAgentListNames}
                  onChange={(e) => {
                    setSelectedValue(e.value);
                  }}
                  className="w-full"
                />
              </div>
            )}
          </div>
          {popUpDisplay && (
            <BlockPopup
              visible={popUpDisplay}
              footerContent={confirmationPopUpfooterContent}
              header={header}
              content={content}
            />
          )}
        </div>
      </Sidebar>
    </>
  );
};

export default DashboardLeftPanel;
