import { ThemeContext } from "app/uicomponents/macrocomponents/RootLayout";
import { getObjectFromSessionStore } from "app/utils/sessionStore";
import { getReadableTextColor } from "app/utils/utils";
import { useContext } from "react";

const useBgBasedTextColor = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const lookUpResponse = getObjectFromSessionStore("lookUpResponse");

  // these colors should match with the value --surface-ground variable
  // in /public/themes/*/theme.css
  const bgColor = isDarkMode ? "#040d19" : "#f5f5f5";

  return getReadableTextColor(bgColor, lookUpResponse?.branding?.primaryColor || "#002446");
};

export default useBgBasedTextColor;
