import { PayloadAction } from "@reduxjs/toolkit";
import {
  createKeyPersonProposalContactInfoFetchError,
  createKeyPersonProposalContactInfoFetchSuccess,
  createKeyPersonProposalContactInfoLoading,
  createKPProposalInfoError,
  createKPProposalInfoLoading,
  createKPProposalInfoSuccess,
  GET_KEY_PERSON_CONTACT_INFO,
  GET_KEY_PERSON_PROPOSAL_INFO,
  KP_ROW_SCHEMA,
  KPUpdateProposalRowData,
  UPDATE_ROW_KEY_PERSON_PROPOSAL_INFO,
} from "app/redux/features/create-key-person-proposal";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* KPProposalContactInfoRequest(
  actions: PayloadAction<{
    proposalId: string;
  }>,
): Generator<any, void, any> {
  try {
    const {
      payload: { proposalId },
    } = actions;
    yield put(createKeyPersonProposalContactInfoLoading());
    const response = yield call(() => makeRequest("kpiProposalContactInfo").get(proposalId, true));
    yield put(createKeyPersonProposalContactInfoFetchSuccess(response.data));
  } catch (err: any) {
    yield put(createKeyPersonProposalContactInfoFetchError(err));
  }
}

function* KPProposalInfoRequest(
  actions: PayloadAction<{
    proposalId: string;
  }>,
): Generator<any, void, any> {
  try {
    const {
      payload: { proposalId },
    } = actions;
    yield put(createKPProposalInfoLoading());
    const response = yield call(() => makeRequest("kpiProposalValuation").get(proposalId, true));
    yield put(
      createKPProposalInfoSuccess({
        ...response.data?.data,
        rows: response.data?.data?.rows
          ?.map((el: any) => ({ ...el, isChanged: false }))
          ?.sort((a: KP_ROW_SCHEMA, b: KP_ROW_SCHEMA) => (a.planYear < b.planYear ? -1 : 1)),
      }),
    );
  } catch (err: any) {
    yield put(createKPProposalInfoError(err));
  }
}

function* KPProposalUpdateRow(
  actions: PayloadAction<{
    data: KP_ROW_SCHEMA;
  }>,
): Generator<any, void, any> {
  const {
    payload: { data },
  } = actions;
  yield put(KPUpdateProposalRowData({ data }));
}

function* createKeyPersonProposal() {
  yield takeLatest(GET_KEY_PERSON_CONTACT_INFO, KPProposalContactInfoRequest);
  yield takeLatest(GET_KEY_PERSON_PROPOSAL_INFO, KPProposalInfoRequest);
  yield takeLatest(UPDATE_ROW_KEY_PERSON_PROPOSAL_INFO, KPProposalUpdateRow);
}

export default function* createKeyPersonProposalSaga() {
  yield all([createKeyPersonProposal()]);
}
